<template>
  <div>
    <b-card>
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          {{ 'ID: ' + driver.driver.id }}
          <div class="ml-auto">
            <b-button size="sm" :variant="isOnline ? 'success' : 'danger'" @click="driverStatus">{{ isOnline ? 'Online' : 'Offline' }}</b-button>
          </div>
        </div>
      </template>
      <b-card-text>
        <div v-if="confirmedOrder">
          Order confirmed #{{ confirmedOrder.id }}
        </div>
        <div v-if="!waitingConfirmation">
          <div v-if="orderAvailable">
            <b-button-group size="sm">
              <b-button :disabled="true">
                <strong>{{ orderAvailable.id }}</strong>
              </b-button>
              <b-button @click="accept(orderAvailable.id)" variant="success">Accept</b-button>
              <b-button variant="danger">Refuz</b-button>
            </b-button-group>
          </div>
        </div>
        <div v-else class="text-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <hr />
        <div class="mb-1"  v-for="order in orderPending" :key="order.id">
          <b-button-group size="sm">
            <b-button :disabled="true">
              <strong>{{ order.id }}</strong>
            </b-button>
            <b-button @click="accept(order.id)" variant="success">Accept</b-button>
            <b-button variant="danger">Refuz</b-button>
          </b-button-group>
        </div>
      </b-card-text>
      <template #footer>
        <div v-if="zone" class="d-flex">
          {{ zone.polygonName }}
          <div class="ml-auto">
          {{ zone.priority }} /
          {{ zone.driversCount }}
          </div>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import config from '@/config'
import axios from 'axios'

export default {
  name: 'Simulator',
  components: { },
  data () {
    return {
      isOnline: true,
      zone: null,
      orderAvailable: null,
      orderAvailableParams: null,
      orderPending: [],
      waitingConfirmation: false,
      confirmedOrder: null
    }
  },
  props: {
    driver: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.driver.wsConnection.addEventListener('message', (event) => {
      const message = JSON.parse(event.data)
      this.pendings = message.payload.orders
      // console.log(message)

      if (message.event === 'order_available') {
        this.driver.onSearchNow = true
        this.orderAvailable = message.payload.order
        this.orderAvailableParams = message.payload.params
        setTimeout(() => {
          this.orderAvailable = null
          this.orderAvailableParams = null
          this.driver.onSearchNow = false
        }, message.payload.params.search_time * 1000)
      }
      if (message.event === 'pending_orders_updated') {
        this.orderPending = message.payload.orders
      }
      if (message.event === 'priority_updated') {
        this.zone = message.payload
      }
      if (message.event === 'order_accept_confirmed') {
        this.orderAvailable = null
        this.confirmedOrder = message.payload.order
        this.waitingConfirmation = false
      }
      if (message.event === 'order_canceled') {
        this.confirmedOrder = null
      }
    })
    setInterval(() => {
      this.driver.wsConnection.send(JSON.stringify({
        event: 'currentPosition',
        payload: {
          long: this.driver.positions.lng,
          lat: this.driver.positions.lat,
          orderId: null,
          userId: this.driver.driver.id,
          polygonId: this.driver.driver.name.endsWith('L') ? 90 : 137
        }
      }))
    }, 5000)
  },
  methods: {
    accept (id) {
      const orderId = typeof id !== 'undefined' ? id : this.orderAvailable.id
      axios.post('http://localhost:8082/accept-order', {
        userId: this.driver.driver.id,
        orderId: orderId,
        time: 3
      }).then(() => {
        this.waitingConfirmation = true
        setTimeout(() => {
          this.waitingConfirmation = false
        }, this.orderAvailableParams.search_time * 1000)
      })
    },
    driverStatus () {
      axios.patch(config.baseApiUrl + '/api/user-activity', {
        isOnline: this.isOnline
      }, {
        headers: {
          Authorization: 'Bearer ' + this.driver.apiToken
        }
      }).then(() => {
        this.isOnline = !this.isOnline
      })
    }
  },
  watch: {
    pendingOrders: function () {
      this.driver.wsConnection.addEventListener('message', (event) => {
        const message = JSON.parse(event.data)
        this.pendings = message.payload.orders
      })
    }
  }
}
</script>
